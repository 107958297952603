import { render, staticRenderFns } from "./partyOrg.vue?vue&type=template&id=6b854ebc&scoped=true&"
import script from "./partyOrg.vue?vue&type=script&lang=js&"
export * from "./partyOrg.vue?vue&type=script&lang=js&"
import style0 from "./partyOrg.vue?vue&type=style&index=0&id=6b854ebc&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/_vue-loader@15.10.2@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b854ebc",
  null
  
)

export default component.exports